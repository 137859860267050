<template>
  <ul class="preview-data-list">
    <li class="preview-data-item" v-for="(item,index) of list" :key="index"
      @click="previewImg(item)" :style="{ cursor: item.name === '支部总数' ? 'pointer' :'default' }"
    >
      <img class="item-icon" :src="$currency.ossFileURL(item.icon)" alt="" />
      <div class="preview-data">
        <countTo class="data-count" :startVal="item.old_count" :endVal="item.count" />
        <span class="data-name">{{ item.name }}</span>
      </div>
    </li>
    <el-image-viewer v-if="previewShow" :onClose="closeViewer" :url-list="previewImageUrl" />
  </ul>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: "preview-data-list",
  components: {
    ElImageViewer
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      previewShow: false,
      previewImageUrl: ['https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/2722.png'],
    }
  },
  watch: {

  },

  methods: {
    closeViewer() {
      this.previewShow = false;
    },

    previewImg(item) {
      if (item.name === '支部总数') {
        this.previewShow = true
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../styles/mixin";

  .preview-data-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 22px;

    .preview-data-item {
      display: flex;
      align-items: center;
      width: calc(100% / 3);

      &:nth-child(n + 4) {
        margin-top: 30px;
      }

      .item-icon {
        display: block;
        width: 40px;
      }

      .preview-data {
        //@include flexDirection();
        //min-width: 65px;
        margin-left: 12px;

        .data-count {
          display: block;
          font-size: 20px;
          font-weight: normal;
          color: #FFF;
          //text-align: center;
        }
        .data-name {
          @include ellipsis(1);
          margin-top: 4px;
          font-size: 12px;
          text-align: center;
          color: #8799C0;
          letter-spacing: 1px;
          margin-top: 10px;
        }
      }
    }
  }
</style>
