<template>
  <div class="vanguard-container">
    <s-section-title-bar>重点工作党员先锋队名单</s-section-title-bar>
    <div class="context">
      <el-carousel v-if="current!==0" height="300px" direction="vertical" :interval="7000" :autoplay="true" :loop="true" indicator-position="none" @change="changeCarousel">
        <el-carousel-item v-for="(item,index) in current" :key="item">
          <div class="pioneer-item" v-for="pItem in pioneerList">
            <div class="number">{{pItem.id}}</div>
            <div class="text">
              <text-over-tooltip :refName="`textOverTooltip${pItem.id}`" :placement="'top'" :content="`${pItem.name}`"></text-over-tooltip>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="hint-text" v-if="pioneerList.length === 0">
      暂无数据
    </div>
  </div>
</template>

<script>
import TextOverTooltip from '../TextOverTooltip/index'
import {mapState} from "vuex";

export default {
  name: "vanguard-organization",
  components:{
    TextOverTooltip,
  },
  data() {
    return {
      params:{
        page:1,
        limit:8
      },
      current:0,
      autoplay:false,
    }
  },
  watch:{
    pioneer_total(newVal){
      if (newVal){
        this.current = Math.ceil(newVal / 8)
      }
    }
  },
  computed: {
    ...mapState({
      pioneerList: state => state.routine.pioneerList,
      pioneer_total:state=>state.routine.pioneer_total,
      pioneerLoading: state => state.routine.pioneerLoading
    })
  },
  mounted() {
    this.getPioneerList()
  },
  methods:{
    getPioneerList(){
      this.$store.dispatch('getVanguardRankList', this.params);
      this.current = Math.ceil(this.pioneer_total / 8)
    },

    changeCarousel(){
      if (this.params.page < this.current){
        this.params.page++
      }else {
        this.params.page = 1
      }

      this.$store.dispatch('getVanguardRankList', this.params);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";
.vanguard-container {
  width: 95%;
  //padding: 0 19px;
  margin-top: 20px;
  .pioneer-item{
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 14px;
    $font-color: #C0CCE4;
    color: $font-color;

    & + .table-item {
      margin-top: 20px;
    }
    .number{
      width: 30px;
    }
    .text{
      margin-left: 10px;
      width: calc(100% - 50px);
      /*overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;*/
    }
  }
  .context{
    min-height: 180px;
  }

  .hint-text {
    width: 100%;
    //height: 250px;
    font-weight: 400;
    font-size: 14px;
    //line-height: 250px;
    color: $medium-font-color;
    text-align: center;
    height: 180px;
    line-height: 180px;
  }
}
</style>
