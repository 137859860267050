<template>
  <div class="right-container">
    <div class="top-content">
<!--      <btn-group size="medium" :list="[{name: '常规检查'}, {name: '电子烟'}]" />-->
      <el-popconfirm
        confirm-button-text='确认'
        cancel-button-text='取消'
        confirm-button-type="text"
        @confirm="handleLoginOut"
        icon="el-icon-info"
        icon-color="#f90"
        title="是否要退出登录？">
        <img
          slot="reference"
          class="about-icon"
          :src="$currency.ossFileURL('login-out')"
          alt="" />
      </el-popconfirm>
      <div class="user-info">
        <div class="user-info-content">
          <img class="avatar" :src="$currency.ossFileURL('user')" alt="" />
          <div class="info">
            <span class="username">{{ username }}</span>
            <span class="role">{{ admin_name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="type==0">
        <div class="real-time-examine">
          <s-section-title-bar>累计数据</s-section-title-bar>

          <preview-data-list :list="realTimeExamineList" />
<!--          <preview-data-list :list="list2" />-->
        </div>

<!--        <div class="every-day-contrast">-->
<!--          <div class="title-bar">-->
<!--            <s-section-title-bar>昨日数据</s-section-title-bar>-->
<!--            <span class="hint-text">{{ `数据更新于${update_date}` }}</span>-->
<!--          </div>-->
<!--          <ul class="three-data-list">-->
<!--&lt;!&ndash;            <li class="preview-data-item" v-for="item of dailyComparisonList" :key="item.id">&ndash;&gt;-->
<!--            <li class="preview-data-item" v-for="item of list3" :key="item.id">-->
<!--              <countTo class="data-count" :startVal="item.last_num" :endVal="item.num" />-->
<!--              <span class="data-name">{{ item.name }}</span>-->
<!--              <div class="preview-data">-->
<!--                <span class="compare-text">{{ `较昨日${item.ratio}` }}</span>-->
<!--                <img v-if="item.color === 'green'" class="arrow" :src="$currency.ossFileURL('arrow-down')" alt="" />-->
<!--                <img v-else-if="item.color === 'red'" class="arrow" :src="$currency.ossFileURL('arrow-up')" alt="" />-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

        <div class="recent-examine-analyse">
          <s-section-title-bar>近期会议开展</s-section-title-bar>

          <div class="options-bar">
            <el-tabs v-model="activeName" @tab-click="handleChange">
              <el-tab-pane label="今天"   name="0"/>
              <el-tab-pane label="近7天"  name="1"/>
              <el-tab-pane label="近30天" name="2"/>
              <el-tab-pane label="近90天" name="3"/>
            </el-tabs>
          </div>
          <div
            id="pile-chart"
            class="pile-chart"
            v-loading="loading"
            element-loading-text="数据加载中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(15,36,87,.85)"
          ></div>
        </div>

      <unit-examine />

      <person-ranking />
    </div>

    </div>
</template>

<script>
  import btnGroup from "../btn-group";
  import personRanking from './person-ranking'
  import unitExamine from '../left/unit-examine'
  import previewDataList from '../common/preview-data-list'
  import {Charts} from "../../views/home/charts";
  import {mapState} from "vuex"

  export default {
    name: "right-container",
    components: {
      personRanking,
      btnGroup,
      previewDataList,
      unitExamine,
    },
    data() {
      return {
        activeName: '2',
        abc: "",
        list: [
          { label: '每日对比', value: 1 },
          { label: '每月对比', value: 2 },
          { label: '每年对比', value: 3 },
        ],

        list2: [
          { icon: 'retail-sum',     old_count: 0, count: 1862, name: '近日活跃' },
          { icon: 'holder',         old_count: 0, count: 554,   name: '开展活动' },
          { icon: 'not-holder',     old_count: 0, count: 253,   name: '开展会议' },
          { icon: 'accomplish',          old_count: 0, count: 8265,  name: '参与活动人次' },
          { icon: 'await', old_count: 0, count:3652,  name: '参与会议人次' },
          { icon: 'examine-person',     old_count: 0, count: 25,    name: '请假人数' },
        ],

        list3: [
          { id: 1, last_num: 0, num: 52, color: 'green',   ratio: '10%', name: '今日活跃党员数' },
          { id: 2, last_num: 0, num: 43, color: 'red',     ratio: '40%', name: '开展活动次数' },
          { id: 3, last_num: 0, num: 1882, color: 'green', ratio: '20%', name: '开展会议次数' },
        ],
      }
    },

    computed: {
      ...mapState({
        update_date: state => state.update_date,
        username:  state => state.user.username,
        admin_name:  state => state.user.admin_name,
        realTimeExamineList: state => state.routine.realTimeExamineList,
        dailyComparisonList: state => state.routine.dailyComparisonList,
        checkStatisticsList: state => state.routine.checkStatisticsList,
        loading: state => state.routine.analyseLoading,
        type: state => state.electronic.type,
      }),
    },

    watch: {
      checkStatisticsList(newVal) {
        Charts.initPileChart('pile-chart', +this.activeName, newVal);
      },
    },

    methods: {

      handleChange() {
        this.$store.commit('getActiveName',this.activeName)
        const params = {time_type: +this.activeName};
        this.$store.dispatch('getCheckStatisticsData', params);
      },

      // 退出登录
      async handleLoginOut() {
        const {level, message} = await this.$api.loginOut();
        if (level === 'success') {
          this.$notify.success(message);
          this.$store.commit('clearMyAuthCityList');
          this.$store.commit('saveUserToken', "");
        }
      },


    }
  }
</script>

<style lang="scss" scoped>

  @import "../../styles/mixin";
  $right-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');

  .right-container {
    width: 27.4%;
    height: 100%;
    background-image: $right-container-bg;

    .top-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 15px;
      padding-left: 185px;
      height: 80px;


      .about-icon {
        display: block;
        width: 48px;
        margin: 0 25px;
        cursor: pointer;
        transition: opacity .1s;

        &:hover {
          opacity: .70;
        }
      }

      .user-info {
        flex: 1;
        position: relative;
        @include flex(center, flex-start);

        &::after {
          content: "";
          position: absolute;
          top: 12%;
          left: 0;
          height: 80%;
          width: 1px;
          border-left: 1px dashed $separate-color;
        }

        .user-info-content {
          display: flex;
          align-items: center;
          min-width: 140px;
          padding: 6px 10px;
          background-color: #213c87;
          border-radius: 30px;

          .avatar {
            display: block;
            width: 30px;
            margin-right: 10px;
          }

          .info {
            @include flexDirection();
            .username {
              color: #fff;
              font-size: 16px;
              font-weight: 400;
              white-space: nowrap;
              // text-transform: capitalize;
            }
            .role {
              color: #81A9F1;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }

    }

    .real-time-examine {
      width: 100%;
      margin-top: 20px;
      padding: 0 19px;
    }

    .every-day-contrast {
      width: 100%;
      margin-top: 40px;
      padding: 0 19px;

      .title-bar {
        @include flex(space-between, center);
        width: 100%;

        .hint-text {
          font-weight: 400;
          font-size: 12px;
          white-space: nowrap;
          color: $medium-font-color;
        }
      }

      .three-data-list {
        height: 55px;// 固定高度
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 22px;

        .preview-data-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(100% / 3);

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 25%;
            height: 50%;
            border-left: 1px dashed #4c6d98;
          }

          &:nth-child(3n + 1)::before {
            content: none;
          }

          &:nth-child(n + 4) {
            margin-top: 25px;
          }

          .data-count {
            font-size: 20px;
            font-weight: normal;
            color: #FFF;
          }

          .data-name {
            @include ellipsis(1);
            margin-top: 4px;
            font-size: 12px;
            color: #8799C0;
            letter-spacing: 1px;
          }

          .preview-data {
            width: 100%;
            @include flex();
            padding-left: 8px;

            .compare-text {
              margin-top: 4px;
              font-size: 12px;
              font-weight: 400;
              color: #8799C0;
            }

            .arrow {
              display: block;
              width: 16px;
            }

          }
        }
      }
    }

    .recent-examine-analyse {
      position: relative;
      width: 100%;
      margin-top: 20px;
      padding: 0 19px;
      .options-bar {
        position: absolute;
        top: 10px;
        left: 10%;
        display: flex;
        width: 100%;
        align-items: center;
        z-index: 999;
      }

      #pile-chart {
        width: 100%;
        height: 220px;
        margin-top: 30px;
      }
    }
  }
</style>
