<template>
  <section class="left-container">

    <div class="top-content">

      <div class="weather-info content-item">
        <img class="img" src="https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/logo.png" alt="" style="" />
<!--        <img class="weather-icon" :src="$currency.ossFileURL(`${weather.wea_img?weather.wea_img:'zy_logo'}`)" alt=""/>-->
<!--        <div class="weather-temperature">-->
<!--          <span class="temperature-num">{{weather.tem?weather.tem+"°":""}}</span>-->
<!--          <span class="weather-name">{{weather.wea}}</span>-->
<!--        </div>-->
      </div>

      <div class="current-time content-item">
        <time class="time-num">{{ currentTime }}</time>
        <time class="date-num">{{ currentDate }}</time>
      </div>

      <div class="address-info content-item">
        <div class="dropdown-info">
<!--          <div class="province-city">{{ address }}</div>-->
          <div class="province-city">中原出版传媒集团党委</div>
<!--          <img class="handle-icon" :src="$currency.ossFileURL('switchover')" alt=""/>-->
        </div>
        <el-cascader
          disabled
          ref="myCascader"
          :props="{ checkStrictly: true, expandTrigger: 'hover' }"
          :options="myAuthAreaList"
          @change="addressChange"/>
      </div>
    </div>

    <div class="area-preview-data" v-if="type==0">
      <s-section-title-bar>组织内概况</s-section-title-bar>
      <preview-data-list :list="areaInsideList"/>
    </div>

    <examine-feedback />

    <content-statistics />

<!--    <unit-examine />-->
    <vanguard-organization />

  </section>
</template>

<script>
import examineFeedback from './examine-feedback'
import contentStatistics from './content-statistics'
// import unitExamine from "./unit-examine"
import {mapState} from "vuex"
import previewDataList from "../common/preview-data-list";
import vanguardOrganization from '../right/vanguard-organization'

export default {
  name: "left-container",
  components: {
    examineFeedback,
    contentStatistics,
    // unitExamine,
    previewDataList,
    vanguardOrganization,
  },
  data() {
    return {
      logo:'logo',
    }

  },

  computed: {
    ...mapState({
      currentDate: state => state.current_date,
      currentTime: state => state.current_time,
      org_area_id: state => state.user.org_area_id,
      areaInsideList: state => state.routine.areaInsideList,
      myAuthAreaList: state => state.address.myAuthAreaList,
      address: state => state.address.address,
      weather:  state => state.weather_info,
      type: state => state.electronic.type,
    }),
  },

  methods: {

    addressChange(e) {
      const id = e[e.length-1];
      const nameList = this.$refs.myCascader.getCheckedNodes()[0].pathLabels;
      let name = nameList.length === 1 ? `陕西省${nameList[0]}` : nameList.join("");
      if (nameList[0] === "陕西省") name = `陕西省`;
      this.$store.commit('editCityAreaName', name);
      this.$store.commit('setParentId', e.length === 2 ? e[0] : "");
      this.$store.commit('setOrgAreaId', id);
      this.$refs.myCascader.dropDownVisible = false


      let placeName = nameList.slice(-1).join().substring(0,nameList.slice(-1).join().length-1)
      if(placeName==='陕西'){
          placeName = '西安'
      }else if(placeName==='市辖'){
          placeName = '西安'
      }

      this.$store.dispatch('GET_WEATHER_INFO',placeName)

    },

  }
}
</script>

<style lang="scss" scoped>

@import "../../styles/mixin";

$left-container-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg-left.png');

.left-container {
  width: 27.4%;
  height: 100%;
  background-size: 100% auto;
  background-image: $left-container-bg;
  background-repeat: no-repeat;
  padding: 0 0 0 40px;

  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    //margin-bottom: 15px;

    .content-item {
      position: relative;

      & + .content-item::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 20px;
        background-color: #4C6D98;
        transform: translateY(-50%);
      }
    }

    .current-time {
      @include flexDirection();
      align-items: center;
      width: 30%;

      .time-num {
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
      }

      .date-num {
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
      }
    }

    .weather-info {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20%;

      .img{
        width: 70px;
        height: 70px;
        object-fit: contain;
        margin-right: 10px;
        //background-color: rgb(17,89,155);
      }

      .weather-icon {
        display: block;
        width: 32px;
      }

      .weather-temperature {
        display: flex;
        align-items: flex-end;
        margin-left: 10px;
        color: #FFF;

        .temperature-num {
          font-weight: 400;
          font-size: 24px;
          height: 22px;
          line-height: 22px;
        }

        .weather-name {
          font-size: 12px;
        }
      }
    }

    .address-info {
      position: relative;
      width: 50%;
      cursor: pointer;

      .dropdown-info {
        position: absolute;
        top: 8px;
        left: 0;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .province-city {
        @include ellipsis(1);
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 1px;
      }

      .handle-icon {
        width: 30px;
      }
    }

  }

  .area-preview-data {
    width: 100%;
    margin-top: 20px;

    .preview-data-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .preview-data-item {
        display: flex;
        align-items: center;
        width: calc(100% / 3);
        margin-top: 25px;

        .item-icon {
          display: block;
          width: 36px;
        }

        .preview-data {
          @include flexDirection();
          margin-left: 12px;

          .data-count {
            font-size: 20px;
            font-weight: normal;
            color: #FFF;
          }

          .data-name {
            @include ellipsis(1);
            font-size: 12px;
            color: #8799C0;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
</style>
