<template>
  <div id="dv-full-screen-container" :ref="ref">
    <template v-if="ready">
      <slot></slot>
    </template>
  </div>
</template>

<script>
import autoResize from './mixin/autoResize.js'

export default {
  name: 'DvFullScreenContainer',
  mixins: [autoResize],
  data () {
    return {
      ref: 'full-screen-container',
      allWidth: 0,
      scale: 0,
      datavRoot: '',
      ready: false
    }
  },

  methods: {
    afterAutoResizeMixinInit () {
      const { initConfig, setAppScale } = this

      initConfig()

      setAppScale()

      this.ready = true
    },
    initConfig () {
      const { dom } = this
      const { width, height } = screen

      const domWidth = width > 1920 ? width : 1920
      const domHeight = height > 1080 ? height : 1080

      dom.style.width = `${domWidth}px`
      dom.style.height = `${domHeight}px`

      this.allWidth = domWidth
    },

    setAppScale () {
      const { allWidth, dom } = this

      const currentWidth = document.body.clientWidth

      dom.style.transform = `scale(${currentWidth / allWidth})`
    },
    onResize () {
      const { setAppScale } = this

      setAppScale()
    }
  }
}
</script>

<style lang="scss">
#dv-full-screen-container {
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
</style>
